import React, { useMemo, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'common/components/UI/ContainerTwo';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import FeatureBlock from 'common/components/FeatureBlock';
import SectionWrapper, { SectionHeader, ServiceWrapper } from './service.style';
import { NETWORK, TOKEN_DETAIL } from '../../../utils/constsants';
import { Connection, PublicKey } from '@solana/web3.js';
import { generateTokenText, lamportsToSol } from '../../../utils/misc';
import { callCoinGeckoApi, coinGeckoUrl } from '../../../utils/apis';
// import data from 'common/data/AgencyModern';


const Services = () => {
  const [accountBalances, setBalances] = useState();
  const data = useStaticQuery(graphql`
    query {
      agencyModernJson {
        services {
          id
          title
          description
          thumbnail_url {
            publicURL
          }
        }
      }
    }
  `);
  useMemo(()=>{
    async function fetchBalances () {
      console.log('fetched balances');
      const connection = new Connection(NETWORK);
      const balances = {
        sol: lamportsToSol(await connection.getBalance(new PublicKey(TOKEN_DETAIL.sol.address))),
        samo: (await connection.getTokenAccountBalance(new PublicKey(TOKEN_DETAIL.samo.address))).value.uiAmount,
        usdc: (await connection.getTokenAccountBalance(new PublicKey(TOKEN_DETAIL.usdc.address))).value.uiAmount,
      }

      let priceData = await callCoinGeckoApi(coinGeckoUrl);
      const solPrice = (priceData.find(result =>  {return result.symbol.toLowerCase() === 'sol'}).current_price) || 0;
      const samoPrice = (priceData.find(result =>  {return result.symbol.toLowerCase() === 'samo'}).current_price) || 0; 
      const totalValue = (balances.sol * solPrice) + (balances.samo * samoPrice) + balances.usdc;
      console.log(totalValue);

      const balanceLabels = {
        sol: generateTokenText(balances.sol, TOKEN_DETAIL.sol.label), 
        samo: generateTokenText(balances.samo, TOKEN_DETAIL.samo.label), 
        usdc: generateTokenText(balances.usdc, TOKEN_DETAIL.usdc.label), 
        total: generateTokenText(totalValue,'$')
      }
      setBalances(balanceLabels);

    }
    fetchBalances();
  }
  ,[])

  return (
    <SectionWrapper id="services">
      <Container>
        <Fade up delay={100}>
          <SectionHeader>
            <Heading content="About SamoDAO" />
            <Text content="SamoDAO is a Solana-based community concentrated on navigating the emerging world of crypto while helping each other become more informed along the way. Whether you're new to crypto or an industry veteran, we're a community you can rely on to keep tabs on what's going on in crypto." />
            <br></br>
            <Text content="If you're looking to become more informed, become a better investor or trader, and/or make a name for yourself and be rewarded by sharing your insights with SamoDAO & the world, this community is for you." />
            {
              accountBalances && <>
                <Heading className="heading2" content="SamoDAO Treasury Balance:" />
                <Text content={`•${accountBalances.sol}`} />
                <Text content={`•${accountBalances.samo}`} />
                <Text content={`•${accountBalances.usdc}`} />
                <Text content={`•Total Value: ${accountBalances.total}`} />
              </>
            }
          </SectionHeader>
        </Fade>
        <ServiceWrapper>
          {data.agencyModernJson.services.map((item, index) => {
            return (
              <Fade up delay={130 * item.id} key={`service-key-${item.id}`}>
                <FeatureBlock
                  key={`post_key-${index}`}
                  id={`post_id-${item.id}`}
                  className="service__item"
                  icon={
                    <Image
                      src={item.thumbnail_url.publicURL}
                      alt={`Blog Image ${item.id}`}
                      objectFit="cover"
                      className="service__image"
                    />
                  }
                  iconPosition="left"
                  title={<Heading as="h4" content={item.title} />}
                  description={<Text content={item.description} />}
                />
              </Fade>
            );
          })}
        </ServiceWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Services;
