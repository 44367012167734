import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { checkmarkCircled } from 'react-icons-kit/ionicons/checkmarkCircled';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import { SectionHeader } from '../appModern.style';
import SectionWrapper, {
  PricingArea,
  InnerWrapper,
  PricingCard,
} from './pricingPolicy.style';

import crown from 'common/assets/image/AppClassic/crown.svg';

const PricingPolicy = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        pricing {
          title
          slogan
          slogan2
          slogan3
          monthly {
            id
            title
            features {
              id
              text
              url
            }
          }
        }
      }
    }
  `);
  const { title, slogan, slogan2, monthly } = data.appModernJson.pricing;
  const [state] = useState({
    active: 'monthly',
    pricingPlan: monthly,
  });

  return (
    <SectionWrapper id="pricing">
      <Container>
        <SectionHeader>
          <Heading content={title} />
          <Heading as="h5" content={slogan} />
          <Heading as="h5" content={slogan2}/>
          <h5><a href="https://discord.gg/xDjWyTZn9Y" target="_blank" rel="noreferrer">JOIN OUR DISCORD & VERIFY YOUR WALLET</a></h5>
        </SectionHeader> 

        <PricingArea>
          <InnerWrapper>
            {state.pricingPlan.map((item) => (
              <PricingCard key={`${state.active}-card--key${item.id}`}>
                {item.suggested && (
                  <span className="tag">
                    <img src={crown} alt="Crown" /> Suggested
                  </span>
                )}
                <div className="card-header">
                  <Heading as="h3" content={item.title} />
                  <Text content={item.description} />
                </div>
                <div className="card-body">
                  <ul className="feature-list">
                    {item.features.map((item) => (
                      <li key={`${state.active}-feature--key${item.id}`}>
                        <Icon icon={checkmarkCircled} /> 
                        <a style={{color: 'black'}} href={item.url} target='_blank'>{item.text}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </PricingCard>
            ))}
          </InnerWrapper>
        </PricingArea>
      </Container>
    </SectionWrapper>
  );
};

export default PricingPolicy;
