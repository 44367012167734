/* eslint-disable */
import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { playCircle } from 'react-icons-kit/fa/playCircle';
import { openModal, closeModal } from '@redq/reuse-modal';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Rating from 'common/components/Rating';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
} from './banner.style';

import microsoft from 'common/assets/image/appModern/envato-icon.png';
import videoBanner1 from 'common/assets/image/appModern/reportSlide.png';
import videoBanner2 from 'common/assets/image/appModern/sentiment3.png';
import circleBorder from 'common/assets/image/appModern/shape.svg';
import mockup from 'common/assets/image/appModern/banner3.png';
import { openUrl } from '../../../utils/misc';
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = (img) => (
  <ImageWrapper>
    <img
      title="How to Stake on Solana"
      src={img}
      frameBorder="0"
    />
  </ImageWrapper>
);


const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        client {
          id
          title
          url
          image {
            publicURL
          }
        }
        company {
          id
          title
          url
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { client } = data.appModernJson;
  const { company } = data.appModernJson;
  // modal handler
  const handleImgModal = (img) => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
        },
      },
      component: ()=>ModalContent(img),
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Solana’s First Research &
Insights Focused DAO"
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="Navigating crypto is hard. To do it well, you need a tribe."
            />
            <Text
            content="SamoDAO is a community of researchers, traders, and investors
            seeking to share insights & findings with each other and the market. Together, we're GMI and have fun along the way."
            />
          </Fade>
          <Fade up delay={300}>
          <ButtonGroup>
            <Button className="primary" title="Join our Discord!" onClick={()=>openUrl('https://discord.gg/8Fq3Z568tp')}/>
            <div className="divider" ></div>
            <Button className="primary" title="Follow on Twitter!" onClick={()=>openUrl('https://twitter.com/Samo_DAO')}/>
          </ButtonGroup>
          </Fade>
          <Fade up delay={200}>
            <Text
              content="VIEW PAST TRADE OR FADE SLIDE 👇"
              style={{ textDecoration: 'underline', fontWeight: '600', margin: '24px 0 10px' }}
            />
          </Fade>
          <VideoGroup>
            <img
              src={videoBanner1}
              width={220}
              height={139}
              onClick={()=>openUrl("https://docs.google.com/presentation/d/1EnTbXnQ6_Nh-H4TjdjDqeESe0E6VJYcM4sU8TVb85zA/edit?usp=sharing")}
              alt="Samo Dao Sample Research Report: Orca"
            />
            <img
              src={videoBanner2}
              width={180}
              height={139}
/*               onClick={()=>handleImgModal(videoBanner2)}
 */              alt="Samo Community Sentiment Results"
            />
          </VideoGroup>
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <Image src={mockup} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
      <CustomerWrapper>
        <Text content="Samoyedcoin ($SAMO) 
        available on:" />
        <ImageWrapper>
          {client.map((item) => (
            <a key={`client-key${item.id}`} href={item.url} target='_blank' style={{zIndex: 99}}>
              <Image
                src={item.image.publicURL}
                alt={item.title}
              />
            </a>
          ))}
        </ImageWrapper>
      </CustomerWrapper>
      <CustomerWrapper>
      <Text content="Samoyedcoin NFT 
        available on:" />
        <ImageWrapper>
          {company.map((item) => (
            <a key={`company-key${item.id}`} href={item.url} target='_blank' style={{zIndex: 99}}>
              <Image
                style={{height: '42px'}}
                src={item.image.publicURL}
                alt={item.title}
              />
            </a>
          ))}
        </ImageWrapper>
      </CustomerWrapper>
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  );
};

export default Banner;
