import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppModern/Navbar';
import Banner from 'containers/AppModern/Banner';
import AppSlider from 'containers/AppModern/AppSlider';
import DashboardFeatures from 'containers/AppModern/Dashboard';
import PricingPolicy from 'containers/AppModern/PricingPolicy';
import TeamPortfolio from 'containers/AppModern/TeamPortfoilo';
import Services from 'containers/AppModern/Services';
import FaqSection from 'containers/AppModern/FaqSection';
import ClientBlock from 'containers/AppModern/ClientBlock';
//import Newsletter from 'containers/AppModern/Newsletter';
import Footer from 'containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import Seo from 'components/seo';
import Layout from 'components/layout';

// Sam Bankman-Fried and Anatoly Yakovenko are GOATs
// Program initiate Samoyedcoin ≥ $1 

const AppModern = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="SamoDAO" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <Layout>
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-active">
              <Navbar />
            </Sticky>
            <ContentWrapper>
              <Banner />
              <Services />
              <AppSlider />
              <PricingPolicy />
              <DashboardFeatures />
              <FaqSection />	
              <TeamPortfolio />  
              <ClientBlock /> 
            </ContentWrapper>
            <Footer />
          </AppWrapper>
        </Layout>
      </>
    </ThemeProvider>
  );
};
export default AppModern;

