import axios from "axios";
import { coinGeckoApi, MessariApi } from "../types/apis";

interface AxiosResponse {
    status: number;
    data: coinGeckoApi[];
}
export async function callCoinGeckoApi (uri: string):Promise<coinGeckoApi[]> {
    console.log('Updating token pricing data . . . ')
    try {
        const response: AxiosResponse = await axios.get(uri, 
            {
                timeout: 10000, 
                timeoutErrorMessage: 'coingecko call timeout'
            }
        );
        
        if (response.status === 200) {
            console.log('Token pricing data successfully updated.')
            return response.data;

        } else {
            throw new Error(`Unrecognized non-error response ${JSON.stringify(response)}`);
        }
    }
    catch (error: any) {
        throw new Error("Issues getting CoinGecko Response.");
    }

}


export async function callMessariApi (uri: string):Promise<MessariApi> {
    console.log('Updating transaction cost data . . . ')
    try {
        const response = await axios.get(uri, 
            {
                timeout: 10000, 
                timeoutErrorMessage: 'messari call timeout'
            }
        );
        
        if (response.status === 200) {
            console.log('Transaction data successfully updated.')
            return response.data;

        } else {
            throw new Error(`Unrecognized non-error response ${JSON.stringify(response)}`);
        }
    }
    catch (error: any) {
        throw new Error("Issues getting CoinGecko Response.");
    }

}

export const coinGeckoUrl = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=samoyedcoin%2C%20solana%2C&order=market_cap_desc&per_page=100&page=1&sparkline=false%27;'